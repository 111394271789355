import React from "react";
import ReactDOM from "react-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss?v=1.2.0";
import "assets/demo/demo.css?v=1.2.0";
import Home from "./Home.js";

ReactDOM.render(
  <Home />,
  document.getElementById("root")
);
