import React from "react";
// nodejs library that concatenates strings
import classnames from "classnames";

// reactstrap components
import {
  Collapse,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Button,
  Modal
} from "reactstrap";
import PDF from "components/PDF";

function ExamplesNavbar(props) {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);
  const [modal, setModal] = React.useState(false);
  const toggleModal = () => {
    setModal(!modal);
  };

  const toggleNavbarCollapse = () => {
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 299 ||
        document.body.scrollTop > 299
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 300 ||
        document.body.scrollTop < 300
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <Navbar
      className={classnames("fixed-top", navbarColor)}
      color-on-scroll="300"
      expand="lg"
    >
      <Container>
        <div className="navbar-translate">
          <p style={{ color: "white", cursor: "pointer" }}> <b id="idIndx" onClick={props.switchPage}>Daniel G. Ottah</b></p>

          <button
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
        </div>
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>

            <NavItem>
              <NavLink
                data-placement="bottom"
                // href="/profile"
                target=""
                title="View Resume"
              >
                <i id="idProf" className="fa fa-file-text-o" onClick={toggleModal} style={{ cursor: "pointer" }} aria-hidden="true" />
                <p id="idProf" className="d-lg-none" style={{ cursor: "pointer" }} >My Resume</p>
              </NavLink>
            </NavItem>

            <Modal isOpen={modal} toggle={toggleModal}>
              <div className="modal-header">
                <button
                  aria-label="Close"
                  className="close"
                  type="button"
                  onClick={toggleModal}
                >
                  <span aria-hidden={true}>×</span>
                </button>
                <h5
                  className="modal-title text-center"
                  id="exampleModalLabel"
                >
                  Resume
                  </h5>
              </div>
              <div className="modal-body">
                <PDF />
              </div>
              <div className="modal-footer">
                <div className="left-side">
                  <a
                    href="DG_Ottah_Resume.pdf"
                    download
                  >
                    Download
                    </a>
                </div>
                <div className="divider" />
                <div className="right-side">
                  <Button href="DG_Ottah_Resume.pdf" className="btn-link" color="default" type="button" target="_blank">
                    Open in browser
                    </Button>
                </div>
              </div>
            </Modal>


            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://www.linkedin.com/in/daniel-guy-ottah/"
                target="_blank"
                title="My LinkedIn Page"
              >
                <i className="fa fa-linkedin" />
                <p className="d-lg-none">Linked In</p>
              </NavLink>
            </NavItem>

            <NavItem>
              <NavLink
                data-placement="bottom"
                href="https://github.com/DanielOttah"
                target="_blank"
                title="My GitHub"
              >
                <i className="fa fa-github" />
                <p className="d-lg-none">GitHub</p>
              </NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
}

export default ExamplesNavbar;
