import React, { useState } from "react";


// reactstrap components
import {
  Button,
  CardBody,
  CardTitle,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Alert,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import SectionCarousel from "views/index-sections/SectionCarousel";


function LandingPage(props) {
  const [alertSuccess, setAlertSuccess] = useState(false);
  const [formName, setFormName] = useState('');
  const [formEmail, setFormEmail] = useState('');
  const [formMsg, setFormMsg] = useState('');
  const [showNameError, setShowNameError] = useState('none');
  const [showEmailError, setShowEmailError] = useState('none');
  const [showMessageError, setShowMessageError] = useState('none');
  const [activeTab, setActiveTab] = React.useState("1");


  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("profile-page");
    return function cleanup() {
      document.body.classList.remove("profile-page");
    };
  });

  const checkName = () => {
    if (formName === "") {
      setShowNameError("block");
      return false;
    }
    else {
      setShowNameError("none");
      return true;
    }
  }
  const checkEmail = () => {
    if (formEmail === "") {
      setShowEmailError("block");
      return false;
    }
    else {
      setShowEmailError("none");
      return true;
    }
  }
  const checkMessage = () => {
    if (formMsg === "") {
      setShowMessageError("block");
      return false;
    }
    else {
      setShowMessageError("none");
      return true;
    }
  }

  async function handleFormData() {
    if (!checkName()) {
      return;
    }
    else if (!checkEmail()) {
      return;
    }
    else if (!checkMessage()) {
      return;
    }

    else {
      const data = {
        "name": formName,
        "email": formEmail,
        "desc": formMsg
      }
      try {
        let link = ' https://jf26zhc175.execute-api.us-east-1.amazonaws.com/Prod/email';

        await fetch(link, {
          method: 'POST',
          mode: 'no-cors',
          cache: 'no-cache',
          credentials: 'same-origin',
          headers: {
            'Content-Type': 'application/json'
          },
          redirect: 'follow',
          referrer: 'no-referrer',
          body: JSON.stringify(data)
        });

        // const json = await response.json();
        // console.log(json)
        setAlertSuccess(true)
      }
      catch (err) {
        setAlertSuccess(true)
      }
    }
  }
  return (
    <div>
      <ExamplesNavbar switchPage={props.switchPage} />
      <LandingPageHeader />
      <div className="main">
        <div className="section profile-content">
          <Container>
            <div className="owner">
              <div className="avatar">
                <img
                  alt="..."
                  className="img-circle img-no-padding img-responsive"
                  src={require("assets/img/pix2.jpg")}
                />
              </div>

              <div className="name">
                <h3 className="title" style={{ color: 'black' }}>
                  <strong> Daniel G. Ottah</strong><br />
                </h3>
                <h6 className="description" style={{ color: 'black', fontSize: 16 }}>Full Stack/Software Developer</h6>
                <h6 style={{ color: 'black', fontSize: 16 }} title="source Title">Proficient front/back-end development of websites, software applications, Petroleum Engineering research </h6>
              </div>
              <br />
              <Button
                href="DG_Ottah_Resume.pdf"
                className="btn-round mr-1"
                color="primary"
                target="_blank"
                outline
              >
                View Resume
              <i className="fa fa-play" />
              </Button>

            </div>



            <Row>
              <Col className="ml-auto mr-auto text-center" md="10" style={{ color: 'black' }}>
                <br />
                <p style={{ color: 'black', fontSize: 16 }}>
                  An IT professional with excellent reputation for learning, planning, resolving problems and goal driven. Passionate about applying technical knowledge throughout an application
                  life cycle to ensure products and services meet client specifications. Experienced in Web Application Architecture - designing, testing, troubleshooting, implementing, upgrading
                  applications and enjoy working with people in an agile environment.
              </p>

                <br />

              </Col>
            </Row>
            <Row>
              <Col md="3">
                <div className="info text-center">
                  <div className="icon icon-info ">
                    <i className="nc-icon nc-html5" />
                  </div>
                  <div className="description">
                    <h4 className="info-title" style={{ color: 'black' }}>Responsive Websites</h4>
                    <p className="description " style={{ color: 'black', fontSize: 16 }}>
                      Market yourself on the web with a responsive website; increasing your presence and clients.
                                        </p>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info text-center">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-world-2" />
                  </div>
                  <div className="description">
                    <h4 className="info-title " style={{ color: 'black' }}>RESTful API</h4>
                    <p className="description" style={{ color: 'black', fontSize: 16 }}>
                      Get seemless cloud applications that serve your purpose and tasks.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info text-center">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-laptop" />
                  </div>
                  <div className="description">
                    <h4 className="info-title" style={{ color: 'black' }}>Desktop Apps</h4>
                    <p className="description" style={{ color: 'black', fontSize: 16 }}>
                      Get desktop apps, uniquely designed, that help automate your tasks offline.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="3">
                <div className="info text-center">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-touch-id" />
                  </div>
                  <div className="">
                    <h4 className="info-title" style={{ color: 'black' }}>Unique Development</h4>
                    <p className="" style={{ color: 'black', fontSize: 16 }}>
                      Get apps that are unique to you, to help in your tasks and purpose.
                    </p>
                  </div>
                </div>
              </Col>
              <Col className="ml-auto mr-auto" md="8">
                <div className="nav-tabs-navigation">
                  <div className="nav-tabs-wrapper">
                    <Nav id="tabs" role="tablist" tabs>
                      <NavItem>
                        <NavLink
                          className={activeTab === "1" ? "active" : ""}
                          onClick={() => {
                            toggle("1");
                          }}
                        >
                          <h5 style={{ cursor: "pointer" }}> <strong>Technical Skills</strong></h5>
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          className={activeTab === "2" ? "active" : ""}
                          onClick={() => {
                            toggle("2");
                          }}
                        >
                          <h5 style={{ cursor: "pointer" }}><strong>Expertise</strong></h5>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </div>
                <TabContent activeTab={activeTab} className="text-center">
                  <TabPane tabId="1">
                    <Row>
                      <Col md="6" sm="12">
                        <div style={{ textAlign: "left", fontSize: 16 }}>
                          <ul>
                            <li >Agile development</li>
                            <li >Critical thinking </li>
                            <li >Visual thinking </li>
                            <li >Systems thinking </li>
                            <li > Creative thinking </li>
                            <li >Analytical thinking </li>
                          </ul>
                        </div>
                      </Col>
                      <Col md="6" sm="12">
                        <div style={{ textAlign: "left", fontSize: 16 }}>
                          <ul>
                            <li>Detail and goal-oriented</li>
                            <li>Pair Programming </li>
                            <li>Effective Communication </li>
                            <li>Test Driven Development- TDD </li>
                            <li> Troubleshooting </li>
                            <li>Debugging </li>
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col md="6" sm="12">
                        <div style={{ textAlign: "left", fontSize: 16 }}>
                          <ul>
                            <li>Python/Python Flask</li>
                            <li>JavaScript </li>
                            <li>PHP/Lavarel </li>
                            <li>HTML </li>
                            <li>CSS </li>
                            <li>React </li>
                            <li>Node.js/Express.js </li>
                            <li>ASP.Net </li>
                          </ul>
                        </div>
                      </Col>
                      <Col md="6" sm="12">
                        <div style={{ textAlign: "left", fontSize: 16 }}>
                          <ul>
                            <li>Bootstrap</li>
                            <li>Material UI</li>
                            <li>Database Management Systems</li>
                            <li>AWS/Heroku/Azure </li>
                            <li>Github </li>
                            <li>Genetec - Security DeskTM </li>
                            <li>KAPPA Well-Test Application</li>
                            <li>IPM Petroleum Experts </li>
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section text-center section-dark" >
          <Container>
            <h2 className="title">Projects</h2>
            <Row>
              <Col md="4">
                <img
                  alt="..."
                  className="img-rounded img-responsive"
                  src={require("assets/img/cj.jpg")}
                />
                <div className="img-details">
                  <div className="author">
                    <img
                      alt="..."
                      className="img-circle img-no-padding img-responsive"
                      src={require("assets/img/pix2.jpg")}
                    />
                  </div>
                </div>
                <CardBody className="card-plain">
                  <div className="author">
                    <CardTitle tag="h4">Self Taught Journey</CardTitle>
                  </div>
                  <p className="card-description text-center">
                    My journey learning to code. From repeating lines of codes to improved code management and planning. A simple bank account user interface, city information app, simple linked list description, Queue and Stack demo...
                    </p>
                  <hr /><p style={{ color: "white" }}><i className="nc-icon nc-settings" /> HTML | CSS | JS | React | Python-Flask | Node.js | PostgreSQL | AWS | Heroku | RESTful API
                  </p>
                  <progress value="95" max="100"> 95%</progress>
                </CardBody>
                <Button
                  className="btn-round mr-1"
                  color="primary"
                  outline
                  type="button"
                  href="http://learning.journey.s3-website-us-east-1.amazonaws.com"
                  target="_blank"
                >
                  Launch
                </Button>
              </Col>

              <Col md="4">
                <img
                  alt="..."
                  className="img-rounded img-responsive"
                  src={require("assets/img/ipr.jpg")}
                />
                <div className="img-details">
                  <div className="author">
                    <img
                      alt="..."
                      className="img-circle img-no-padding img-responsive"
                      src={require("assets/img/pix2.jpg")}
                    />
                  </div>
                </div>
                <CardBody className="card-plain">
                  <div className="author">
                    <CardTitle tag="h4">Integrated Petroleum Resources</CardTitle>
                  </div>
                  <p className="card-description text-center">
                    My very first HTML and CSS responsive website. A static site with some minimal JavaScript queries. Okay for a first...
                    </p>
                  <hr /><p style={{ color: "white" }}><i className="nc-icon nc-settings" /> HTML | CSS | JS</p>
                  <progress value="100" max="100"> 100%</progress>

                </CardBody>
                <Button
                  className="btn-round mr-1"
                  color="primary"
                  outline
                  type="button"
                  href="http://www.iprpetroleum.com/"
                  target="_blank"
                >
                  Launch
                </Button>
              </Col>

              {/* <Col md="4">
                <img
                  alt="..."
                  className="img-rounded img-responsive"
                  src={require("assets/img/tsc.jpg")}
                />
                <div className="img-details">
                  <div className="author">
                    <img
                      alt="..."
                      className="img-circle img-no-padding img-responsive"
                      src={require("assets/img/pix2.jpg")}
                    />
                  </div>
                </div>
                <CardBody className="card-plain">
                  <div className="author">
                    <CardTitle tag="h4">The Super Church</CardTitle>
                  </div>
                  <p className="card-description text-center">
                    A simple non-profit organization website. Great functionality, responsive and slick.
                    </p>
                  <hr /><p style={{ color: "white" }}><i className="nc-icon nc-settings" /> HTML | CSS | JS</p>

                </CardBody>
                <Button
                  className="btn-round mr-1"
                  color="primary"
                  outline
                  type="button"
                  href="http://www.thesuperchurch.org"
                  target="_blank"
                >
                  Launch
                </Button>
              </Col> */}
              <Col md="4">
                <img
                  alt="..."
                  className="img-rounded img-responsive"
                  src={require("assets/img/faceapp.jpg")}
                />
                <div className="img-details">
                  <div className="author">
                    <img
                      alt="..."
                      className="img-circle img-no-padding img-responsive"
                      src={require("assets/img/pix2.jpg")}
                    />
                  </div>
                </div>
                <CardBody className="card-plain">
                  <div className="author">
                    <CardTitle tag="h4">Face Recognition App</CardTitle>
                  </div>

                  <p className="card-description text-center">
                    A simple app to detect a face in a picture. Great functionality, responsive and nice UI. Implemented PostgreSQL database to store users. Applied bcrypt library to encrypt password and Node.js for the API.
                    </p>
                  <hr /><p style={{ color: "white" }}><i className="nc-icon nc-settings" /> HTML | CSS | React | Node.js | Express.js | Heroku | PostgreSQL</p>
                  <progress value="95" max="100"> 95%</progress>

                </CardBody>
                <Button
                  className="btn-round mr-1"
                  color="primary"
                  outline
                  type="button"
                  href="http://face-capture-app.herokuapp.com/"
                  target="_blank"
                >
                  Launch
                </Button>
              </Col>

            </Row>
            <br />
            <Row>
              <Col md="4">
                <img
                  alt="..."
                  className="img-rounded img-responsive"
                  src={require("assets/img/gagzisart.jpg")}
                />
                <div className="img-details">
                  <div className="author">
                    <img
                      alt="..."
                      className="img-circle img-no-padding img-responsive"
                      src={require("assets/img/pix2.jpg")}
                    />
                  </div>
                </div>
                <CardBody className="card-plain">
                  <div className="author">
                    <CardTitle tag="h4">Gagzi's Art</CardTitle>
                  </div>

                  <p className="card-description text-center">
                    An art display website. Oil on canvas and pencil works. Bring art to life.</p>
                  <hr /><p style={{ color: "white" }}><i className="nc-icon nc-settings" /> HTML | CSS | JS | Node.js | AWS | PostgreSQL | Heroku
                      </p>
                  <progress value="100" max="100"> 100%</progress>
                </CardBody>
                <Button
                  className="btn-round mr-1"
                  color="primary"
                  outline
                  type="button"
                  href="https://gagzisart.com"
                  target="_blank"
                >
                  Launch
                </Button>
              </Col>

              <Col md="4">
                <img
                  alt="..."
                  className="img-rounded img-responsive"
                  src={require("assets/img/kcc.jpg")}
                />
                <div className="img-details">
                  <div className="author">
                    <img
                      alt="..."
                      className="img-circle img-no-padding img-responsive"
                      src={require("assets/img/pix2.jpg")}
                    />
                  </div>
                </div>
                <CardBody className="card-plain">
                  <div className="author">
                    <CardTitle tag="h4">King's Court</CardTitle>
                  </div>

                  <p className="card-description text-center">
                    A nonprofit organization website. Great theme, design and functionality </p>
                  <hr /><p style={{ color: "white" }}><i className="nc-icon nc-settings" /> HTML | CSS | Bootstrap | JS | Node.js | Azure
                      </p>
                  <progress value="100" max="100"> 100%</progress>
                </CardBody>
                <Button
                  className="btn-round mr-1"
                  color="primary"
                  outline
                  type="button"
                  href="https://www.rccgairdrie.org"
                  target="_blank"
                >
                  Launch
                </Button>
              </Col>

              <Col md="4">
                <img
                  alt="..."
                  className="img-rounded img-responsive"
                  src={require("assets/img/mbe.jpg")}
                />
                <div className="img-details">
                  <div className="author">
                    <img
                      alt="..."
                      className="img-circle img-no-padding img-responsive"
                      src={require("assets/img/pix2.jpg")}
                    />
                  </div>
                </div>
                <CardBody className="card-plain">
                  <div className="author">
                    <CardTitle tag="h4">Reservoir Engineering Analysis Tool</CardTitle>
                  </div>

                  <p className="card-description text-center">
                    Analyse reservoir engineering data to estimate hydrocarbon in place, reservoir drive and forecast hydrocarbon production. </p>
                  <hr /><p style={{ color: "white" }}><i className="nc-icon nc-settings" /> React | Bootsrap | Python | AWS
                      </p>
                  <progress value="25" max="100"> 25%</progress>
                </CardBody>
                <Button
                  className="btn-round mr-1"
                  color="primary"
                  outline
                  type="button"
                  href="#"
                  target="_blank"
                >
                  {/* {` ${icon} Coming soon`} */}
                  {/* <i class="fa fa-spinner fa-spin fa-2x fa-fw"></i>
                  <span class="sr-only">Coming soon ...</span> */}
                   In Development
                </Button>
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col md="4">
                <img
                  alt="..."
                  className="img-rounded img-responsive"
                  src={require("assets/img/mr360.png")}
                />
                <div className="img-details">
                  <div className="author">
                    <img
                      alt="..."
                      className="img-circle img-no-padding img-responsive"
                      src={require("assets/img/pix2.jpg")}
                    />
                  </div>
                </div>
                <CardBody className="card-plain">
                  <div className="author">
                    <CardTitle tag="h4">MR360 Lightlabs</CardTitle>
                  </div>

                  <p className="card-description text-center">
                    A website advertising immersive Lighting and Composition for Virtual Reality, Artificial Reality & Mixed Reality.</p>
                  <hr /><p style={{ color: "white" }}><i className="nc-icon nc-settings" /> WordPress | Oxygen Builder | Custom CSS | Custom JS
                      </p>
                  <progress value="40" max="100"> 40%</progress>
                </CardBody>
                <Button
                  className="btn-round mr-1"
                  color="primary"
                  outline
                  type="button"
                  href="https://mr360lightlabs.com/"
                  target="_blank"
                >
                  In Development
                </Button>
              </Col>
              <Col md="4">
                <img
                  alt="..."
                  className="img-rounded img-responsive"
                  src={require("assets/img/tb.png")}
                />
                <div className="img-details">
                  <div className="author">
                    <img
                      alt="..."
                      className="img-circle img-no-padding img-responsive"
                      src={require("assets/img/pix2.jpg")}
                    />
                  </div>
                </div>
                <CardBody className="card-plain">
                  <div className="author">
                    <CardTitle tag="h4">Testimonial Buzz</CardTitle>
                  </div>

                  <p className="card-description text-center">
                    An ecommerce app website advertising video testimonials.</p>
                  <hr /><p style={{ color: "white" }}><i className="nc-icon nc-settings" /> WordPress | Oxygen Builder | Custom CSS | Custom JS
                      </p>
                  <progress value="40" max="100"> 40%</progress>
                </CardBody>
                <Button
                  className="btn-round mr-1"
                  color="primary"
                  outline
                  type="button"
                  href="https://www.testimonials.buzz/"
                  target="_blank"
                >
                  In Development
                </Button>
              </Col>
              <Col md="4">
                <img
                  alt="..."
                  className="img-rounded img-responsive"
                  src={require("assets/img/pd.png")}
                />
                <div className="img-details">
                  <div className="author">
                    <img
                      alt="..."
                      className="img-circle img-no-padding img-responsive"
                      src={require("assets/img/pix2.jpg")}
                    />
                  </div>
                </div>
                <CardBody className="card-plain">
                  <div className="author">
                    <CardTitle tag="h4">Petro Demo App</CardTitle>
                  </div>

                  <p className="card-description text-center">
                    An oil and gas app designed to help oil and gas comapnies access their data anywhere and carry out "quick-look" analysis to make fast decisions. <br />[Admin login - username : "admin", password: "admin" | Client login - username : "user1@company.com", password: "user"</p>
                  <hr /><p style={{ color: "white" }}><i className="nc-icon nc-settings" /> React | Node JS | Mongo DB | PostgreSQL | Netlify | Heroku
                      </p>
                  <progress value="70" max="100"> 70%</progress>

                </CardBody>
                <Button
                  className="btn-round mr-1"
                  color="primary"
                  outline
                  type="button"
                  href="https://petro-demo.netlify.app/"
                  target="_blank"
                >
                  In Development
                </Button>
              </Col>

            </Row>
          </Container>
        </div>
        <SectionCarousel />
        <div className="section section-dark landing-section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className=" title text-center">Contact Me</h2>
                <div className="contact-form">
                  <Row>
                    <Col md="6">
                      <label style={{ color: "white" }}>*Name</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-single-02" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Name" type="text" onChange={(e) => setFormName(e.target.value)} />
                      </InputGroup>
                      <label className="label label-danger" id="errorName" style={{ display: showNameError }}> Name field cannot be empty</label>

                    </Col>
                    <Col md="6">
                      <label style={{ color: "white" }}>*Email</label>
                      <InputGroup>
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="nc-icon nc-email-85" />
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input placeholder="Email" type="text" onChange={(e) => setFormEmail(e.target.value)} />
                      </InputGroup>
                      <label className="label label-danger" id="errorEmail" style={{ display: showEmailError }}> Email field cannot be empty</label>

                    </Col>
                  </Row>
                  <label style={{ color: "white" }}>*Message</label>
                  <Input
                    placeholder="Contact me on your thoughts and ideas..."
                    type="textarea"
                    rows="4"
                    onChange={(e) => setFormMsg(e.target.value)}
                  />
                  <label className="label label-danger" id="errorMessage" style={{ display: showMessageError }}> Do send a message... Would be nice to hear your thoughts and ideas :)</label>
                  <Row>
                    <Col className="ml-auto mr-auto" md="4">
                      <Button className="btn-fill" color="primary" size="lg" id="btnSubmit" onClick={() => handleFormData()}>
                        Send Message
                      </Button>
                      <br />
                    </Col>
                  </Row>
                  <br />
                  <Alert className="alert-with-icon" color="success" isOpen={alertSuccess}>
                    <Container>
                      <div className="alert-wrapper">
                        <button
                          type="button"
                          className="close"
                          data-dismiss="alert"
                          aria-label="Close"
                          onClick={() => setAlertSuccess(false)}
                        >
                          <i className="nc-icon nc-simple-remove" />
                        </button>
                        <div className="message">
                          <i className="nc-icon nc-check-2" />Your message sent has been sent. I should get back to you soonest :)
                               </div>
                      </div>
                    </Container>
                  </Alert>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <DemoFooter />
    </div>
  );
}

export default LandingPage;
