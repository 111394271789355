import React, { Component } from "react";
// import ReactDOM from "react-dom";
// import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss?v=1.2.0";
import "assets/demo/demo.css?v=1.2.0";
// pages
import LandingPage from "views/examples/LandingPage.js";
// import ProfilePage from "views/examples/ProfilePage.js";
// others

//  <BrowserRouter>
//         <Switch>
//           <Route path="/index" render={(props) => <LandingPage {...props} />} />

//           <Route
//             path="/profile"
//             render={(props) => <ProfilePage {...props} />}
//           />
//           <Redirect to="/index" />
//         </Switch>
//       </BrowserRouter> 

class Home extends Component {

  handleSwitchPage = (e) => {
    let page = e.target.id;
    let displayPage1 = document.getElementById("idIndex");
    let displayPage2 = document.getElementById("idProfile");
    if (page === "idIndx") {
      displayPage1.style.display = "block";
      displayPage2.style.display = "none";
    }
    if (page === "idProf") {
      displayPage1.style.display = "none";
      displayPage2.style.display = "block";
    }
  }

  render() {
    return (
      <div>
        <div id="idIndex" style={{ display: "block" }}>
          <LandingPage switchPage={this.handleSwitchPage} />
        </div>
        {/* <div id="idProfile" style={{ display: "none" }}>
          <ProfilePage switchPage={this.handleSwitchPage} />
        </div> */}
      </div>

    )
  }
}
export default Home;

