import React from 'react';

function PDF() {
    return (
        <div>
            <object width="100%" height="400" data="https://dgottah.com/DG_Ottah_Resume.pdf" type="application/pdf">   </object>
        </div>
    );
}

export default PDF;