import React from "react";

import { Row, Container } from "reactstrap";

function DemoFooter() {
  return (
    // <footer className="footer footer-black footer-white">
    <footer className="footer footer-white">
      <Container>
        <Row>
          <nav className="footer-nav">
            <ul>
              <li>
                <b> Daniel G. Ottah</b>
              </li>
            </ul>
          </nav>
          <div className="credits ml-auto">
            <span className="copyright">
              <b> © {new Date().getFullYear()}</b>
            </span>
          </div>
        </Row>
      </Container>
    </footer>
  );
}

export default DemoFooter;
