import React from "react";
// import Typed from 'react-typed';

// reactstrap components 
import { Container } from "reactstrap";

function LandingPageHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div
        style={{
          backgroundImage:
            "url(" + require("assets/img/img6.jpg") + ")",
        }}
        className="page-header page-header-xs"
        data-parallax={true}
        ref={pageHeader}
      >

        <div className="filter" />
        <Container>
          <div className="motto text-center">
            <h2> Full Stack Developer/Software Programmer</h2>

            {/* <h1><span style={{ fontWeight: "bold", paddingBottom: 5, color: "rgb(211, 245, 178)" }}>I {`${' '}`}
              <Typed
                strings={['am a Fullstack Developer', 'am a software programmer', 'love to teach', 'love to play chess', 'love to play the guitar', 'am a Petroleum Engineer']}
                typeSpeed={50}
                backSpeed={50}
                loop={true}
                showCursor={false}
              /></span></h1> */}

            {/* <a className="btn btn-primary btn-round mr-1" href="DG_Ottah_Resume.pdf" role="button" target="_blank">View Resume →</a> */}
            <br />
          </div>
        </Container>
      </div>

    </>
  );
}

export default LandingPageHeader;
